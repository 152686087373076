
import { EncryptedUser, Role, TrackingEvent } from '@caresend/types';
import {
  BasicCard,
  ButtonComponent,
  Checkbox,
  Icon,
  getRoute,
  getRouter,
  getStore,
  signOut as signOutFromFirebase,
} from '@caresend/ui-components';
import { isNullish } from '@caresend/utils';
import {
  PropType,
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  watch,
} from 'vue';

import LocationPin from '@/assets/icons/location-pin.svg';
import { unwatchCurrentLocation, watchCurrentLocation } from '@/functions/location';
import { trackEvent } from '@/functions/tracking/tracking';
import { loginRoute } from '@/router/locations';
import { GeolocationPermissionStatus } from '@/store/modules/app';

export default defineComponent({
  name: 'GeoEnforcer',
  components: {
    BasicCard,
    ButtonComponent,
    Checkbox,
    Icon,
    LocationPin,
  },
  props: {
    roles: {
      type: Array as PropType<Role[]>,
      required: true,
    },
  },
  setup(props) {
    const router = getRouter();
    const route = getRoute();
    const store = getStore();

    const authedUser = computed<EncryptedUser | undefined>(() =>
      store.getters['auth/getUser'],
    );

    const geolocationPermissionStatus = computed<GeolocationPermissionStatus | undefined>(() =>
      store.state.app.geolocationPermissionStatus,
    );
    const signOutLoading = ref<boolean>(false);

    const displayEnforcer = computed<boolean>(() =>
      !!geolocationPermissionStatus.value
      && geolocationPermissionStatus.value === GeolocationPermissionStatus.PERMISSION_DENIED
      && !!authedUser.value
      && !!props.roles.includes(authedUser.value?.role),
    );

    const roleBasedCopy = computed<string>(() =>
      authedUser.value?.role === Role.NURSE
        ? 'which is required for getting and completing itineraries.'
        : 'which is required to use CareSend.');

    const userIndicatedLocationAccess = ref<boolean>(false);

    watch(displayEnforcer, () => {
      /** only track event on the first invocation of display enforcer set to true */
      if (route.value.query.retry !== 'true' && displayEnforcer.value) {
        trackEvent(TrackingEvent.GEO_ENFORCEMENT_TRIGGERED);
      }
    });

    const watcherID = ref<number>();
    const isLocationWatching = ref<boolean>(false);

    const maybeUnwatchLocation = () => {
      if (!isNullish(watcherID.value)) {
        unwatchCurrentLocation(watcherID.value);
      }
    };

    const initWatch = async () => {
      maybeUnwatchLocation();
      isLocationWatching.value = true;
      try {
        watcherID.value = await watchCurrentLocation();
      } catch (e) {
        console.info(e);
      }
    };

    watch(authedUser, async () => {
      if (!authedUser.value) return;
      if (!props.roles.includes(authedUser.value?.role)) return;
      if (isLocationWatching.value) return;
      initWatch();
    }, {
      immediate: true,
    });

    let interval: ReturnType<typeof setInterval>;
    watch(geolocationPermissionStatus, (value, oldValue) => {
      if (value === oldValue) return;
      const shouldWatch = () => isLocationWatching.value
        && value !== GeolocationPermissionStatus.ALLOWED;

      if (shouldWatch()) {
        interval = setInterval(() => {
          if (shouldWatch()) {
            initWatch();
          } else {
            clearInterval(interval);
          }
        }, 3000);
      } else {
        clearInterval(interval);
      }
    });

    onBeforeUnmount(() => {
      maybeUnwatchLocation();
    });

    const refreshLocation = () => {
      trackEvent(TrackingEvent.GEO_ENFORCEMENT_RETRY);
      /**
       * we add 'retry' to the query so we know the page was reloaded
       *
       * we don't want to track GEO_ENFORCEMENT_TRIGGERED if the page was
       * reloaded
       */
      router.push({ query: { retry: 'true' } });
      router.go(0);
    };

    const signOut = async () => {
      try {
        signOutLoading.value = true;
        /**
         * we track this before calling signOutFromFirebase() so that the
         * user data can be tracked
         */
        trackEvent(TrackingEvent.GEO_ENFORCEMENT_LOGOUT);
        await signOutFromFirebase();
        router.push(loginRoute());
        trackEvent(TrackingEvent.LOGOUT);
      } finally {
        signOutLoading.value = false;
      }
    };

    const openLocationFaq = () => {
      window.open('https://support.caresend.com/en/articles/6612779-why-do-you-need-my-location');
    };

    return {
      authedUser,
      displayEnforcer,
      refreshLocation,
      signOut,
      signOutLoading,
      openLocationFaq,
      userIndicatedLocationAccess,
      roleBasedCopy,
    };
  },
});
